import { navigate } from '@reach/router';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { Card } from "react-bootstrap";
import { formatPrice } from '../../common/GenericFunction';

export default function SimilarCarousel(props) {
    const { similar } = props
    const [propertyId, setPropertyId] = useState("")
    const [propertDetails, setPropertyDetails] = useState({})
    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1280, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        superLargeDesktop: {
            breakpoint: { max: 1599, min: 1200 },
            items: 1,
            slidesToSlide: 1// optional, default to 1.
        },
    };
    const responsive2 = {
        superExtraLargeDesktop: {
            breakpoint: { max: 3000, min: 1600 },
            items: 7,
            slidesToSlide: 3 // optional, default to 1.
        },
        superLargeDesktop: {
            breakpoint: { max: 1599, min: 1200 },
            items: 6,
            slidesToSlide: 3 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1199, min: 992 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 991, min: 768 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const hanldeOpenDetails = (id, data) => {
        setPropertyId(id)
        setPropertyDetails(data)
        // history.push(`/homedetails/${(data.address).replaceAll(" ", '-')}`) 
        window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")
    }


    return (
        <>
            <Carousel
                swipeable={false}
                draggable={false}
                responsive={(props.detail !== undefined && props.detail) ? responsive1 : responsive2}
                ssr={true} // means to render carousel on server-side.
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container simlilar"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {similar !== undefined && similar && similar.map((data, index) => {
                    return (

                        <div className={`${props?.detail !== null && props?.detail !== undefined && props.detail == true ? style1.simheadres : style1.simhead}`} key={index} onClick={() => hanldeOpenDetails(data._id, data)} aria-hidden role="button" tabIndex="0" >
                            <div className="row">
                                <div className="col">
                                    <div >
                                        <Card className={style1.simheadCard}  >
                                            <div style={{ padding: "10px" }}>
                                                {data?.thumbnail_image !== undefined ? ((data?.thumbnail_image !== null && data?.thumbnail_image !== "") ?
                                                    (data.thumbnail_image.includes("uploads") ? <img src={data.thumbnail_image} alt="image95" height={100} width={180} className={style1.propImage} id={style1.propertyImage} crossOrigin='anonymous' /> : <img src={data.thumbnail_image} alt="image95" height={100} width={180} className={style1.propImage} id={style1.propertyImage} />)
                                                    :
                                                    <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="EditGroup1 " height={100} width={180} className={style1.propImage} id={style1.propertyImage} />) :
                                                    data.location !== undefined && data.location &&

                                                    <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="EditGroup1 " height={100} width={180} className={style1.propImage} id={style1.propertyImage} />
                                                }
                                                <div className={style1.simproperties}>
                                                    <p style={{ fontFamily: "dejavu sans", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{data.address.split(',').slice(0).shift()},</p>
                                                    <p style={{ fontFamily: "dejavu sans" }}>{data.address.split(",").slice(1).join(",")}</p>
                                                    <div>
                                                        {data?.priceRangeMax?.$numberDecimal !== "0"&&data?.priceRangeMax !== null  ?
                                                            <p style={{ fontFamily: "DejaVuSansBold" }}>${formatPrice(data?.priceRangeMin?.$numberDecimal ? data.priceRangeMin.$numberDecimal : "")} - ${formatPrice(data?.priceRangeMax?.$numberDecimal ? data.priceRangeMax.$numberDecimal : "")}</p> :
                                                            <p style={{ fontFamily: "DejaVuSansBold" }}>${formatPrice(data.price.$numberDecimal)}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </Carousel>
        </>
    )
}
